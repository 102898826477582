import React from 'react';
import { Link } from 'react-router-dom';
import './SeoPage.css';
import Hero from '../../layout/HeroSection';
import MainSection from '../../layout/MainSection';
import Cta from '../../layout/Cta';
import FAQ from '../../common/faqSection/FAQ';
import SeoImage from '../../images/seo.avif';
import NetworkImage from '../../images/seo-network.avif';
import womanImage from '../../images/seo-woman.avif';

const seoFAQs = [
    {
        question: "What digital marketing services do you offer?",
        answer: "We offer a range of digital marketing services, including search engine optimisation (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, email marketing, and more. Our services are customized to meet your specific business objectives."
    },
    {
        question: "How long does it take to see results from digital marketing?",
        answer: "The timeline for seeing results varies depending on the strategy used. SEO can take 3-6 months to show significant improvements, while PPC and social media campaigns can generate leads and traffic almost immediately. Consistency and optimization are key to long-term success."
    },
    {
        question: "How do you measure the success of a digital marketing campaign?",
        answer: "We use various metrics to measure success, including website traffic, conversion rates, lead generation, return on ad spend (ROAS), click-through rates (CTR), and customer engagement. We provide monthly reports to keep you informed of your campaign's performance."
    },
    {
        question: "What is SEO, and why is it important?",
        answer: "Search Engine Optimization is the process of optimizing your website to rank higher in search engine results pages (SERPs). It’s important because higher rankings lead to increased visibility, more organic traffic, and better opportunities to convert visitors into customers."
    },
    {
        question: "What is the difference between organic and paid search?",
        answer: "Organic search results are the listings that appear naturally in search engine results due to their relevance to the search query, while paid search results are advertisements that appear at the top or bottom of the search page. Both strategies are important, and we can help with both SEO (organic) and PPC (paid) campaigns."
    },
    {
        question: "Do you manage social media accounts?",
        answer: "Yes, we offer social media management services where we create, schedule, and publish content on your behalf. We also engage with your audience, monitor your brand’s social media presence, and provide analytics to measure performance."
    },
    {
        question: "How much does digital marketing cost?",
        answer: "The cost of digital marketing varies based on the services you need and the scope of the campaign. We offer customized packages to suit different budgets. A typical monthly retainer for comprehensive digital marketing services can range from £1000 or more."
    },
    {
        question: "Can you help create content for my website and social media?",
        answer: "Yes, we have a team of skilled content creators who can produce high-quality content for your website, blogs, social media, and other digital platforms. This includes written content, graphics, videos, and more."
    },
    {
        question: "What platforms do you use for PPC advertising?",
        answer: "We manage PPC campaigns on various platforms, including Google, Facebook, Instagram, LinkedIn Ads, and more. We will choose the best platforms based on your target audience and business goals."
    },
    {
        question: "How do I get started with your digital marketing services?",
        answer: "To get started, simply contact us for a consultation. We will discuss your business goals, assess your current digital presence, and develop a tailored strategy to help you achieve success online."
    }
];


function Seo() {
    return (
        <main>
        <Hero>
            <img src={SeoImage} alt="Seo text"/>
            <section>
                <h1>From total loss to total boss - your success starts here!</h1>
            </section>
        </Hero>
        <section class="main-sections" id="seo">
            <h2>Be ahead of your competitors</h2>
            <article>
                <p class="hidden-left"><b>Our team</b> of experts are specialised in optimising your site, <b>drive more organic traffic</b>, increase visibility, <b>and build trust</b> with your audience. This means more leads, more sales, and solid online presence. Our knowledge can assist new businesses and well-established brands to achieve their KPI’s.</p>
                <img class="seo-network" src={NetworkImage} alt="An image of a network"/>
            </article>
        </section>
        <section class="main-sections">
            <h2>Results we achieve together</h2>
            <section id="advantages">
                <div class="quote-display">
                    <img class="advantage-img" src={womanImage} alt="A woman"/>
                    <q><i>When I design your SEO strategy, I apply customer-centric focus with a bit of magic to achieve the desired result</i></q>
                </div>
                <section class="advantage-display">
                    <div class="advantage hidden-left">
                        <i class="fa-solid fa-chart-line"></i>
                        <p>Increase Your Sales</p>
                    </div>
                    <div class="advantage hidden-left">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        <p>Higher Ranking in Organic Search</p>
                    </div>
                    <div class="advantage hidden-left">
                        <i class="fa-regular fa-lightbulb"></i>
                        <p>Enhanced Brand Awareness</p>
                    </div>
                    <div class="advantage hidden-left">
                        <i class="fa-regular fa-handshake"></i>
                        <p>Build Trust and Credibility</p>
                    </div>
                    <div class="advantage hidden-left">
                        <i class="fa-solid fa-filter-circle-dollar"></i>
                        <p>Improve Conversion Rate</p>
                    </div>
                </section>
            </section>
        </section>
        <section class="main-sections" id="process">
            <h2>What we do at AT Digital to ensure your business achieves its KPI's</h2>
            <section class="process-display">
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <h3>Analysis</h3>
                    <p>We begin by understanding your business objectives, target audience, and competitive landscape. We conduct thorough website audits and keyword research to uncover opportunities and challenges that may impact our strategy.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-gear"></i>
                    <h3>Optimisation</h3>
                    <p>In this phase we will focus on tailoring your website to maximise its search engine potential, based on the insights collected during the Analysis. Our core focus is on the title tags, meta descriptions, site performance, and mobile compatibility to help you stand out in the organic search.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-list-check"></i>
                    <h3>Assesment</h3>
                    <p>We assess the progress of the project, monitoring performance indicators, and providing insightful reports on monthly basis. This ensures the success of your SEO strategy and adaptability to online changes.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-wrench"></i>
                    <h3>Refine</h3>
                    <p>We refine your SEO approach through ongoing assessments, real-time data adjustments, and user behaviour. This ensures the optimisation adapt to market conditions, trends and algorithms, driving long-term success and keeping you ahead of your competition.</p>
                </article>
            </section>
        </section>
        <FAQ faqData={seoFAQs} />
        <MainSection>
            <Cta>
                <p>Ready to elevate your online presence with digital marketing strategies? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="/contact"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
        </main>
    );
}

export default Seo;