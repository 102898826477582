import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './ServicesPage.css';  // Assuming you have your CSS in this file
import webDevImage from '../../images/web_dev.avif';
import ecommerceImage from '../../images/ecommerce.avif';
import seoImage from '../../images/seo.avif';
import socialmediaImage from '../../images/social-media.avif';
import MainSection from '../../layout/MainSection';
import Hero from '../../layout/HeroSection';
import Cta from '../../layout/Cta';
import ServicesImage from '../../images/ServicesImage.avif';


function Services() {
  return (
    <main>
        <Hero>
            <img src={ServicesImage} />
            <section>
                <h1>Transform your business with our comprehensive services</h1>
                <p>Explore a full spectrum of solutions designed to innovate, optimise, and elevate your operations from the ground up.</p>
            </section>
        </Hero>
        <MainSection id="services-overview">
            <div>
                <h2>Our Services</h2>
                <p>Whether you're looking to develop a cutting-edge website, revolutionise your operations through digital transformation, or gain strategic insights from tech consultation, our tailored solutions are here to help you succeed in a digital-first world.</p>
            </div>
            <div className='services-list'>
                <Link to="/webdesign">
                    <article>
                        <h3>Web Design</h3>
                        <p>Transform your ideas into stunning, user-friendly websites with our expert web design services.</p>
                        <img src={webDevImage} alt="A laptop with codes on the screen" />
                    </article>
                </Link>
                <Link to="/ecommerce">
                    <article>
                        <h3>Ecommerce Development</h3>
                        <p>Build powerful ecommerce websites that drive sales and deliver a seamless shopping experience.</p>
                        <img src={ecommerceImage} alt="A person working at a table with a marketing a book and computer" />
                    </article>
                </Link>
                <Link to="/seo">
                    <article>
                        <h3>Search Engine Optimisation</h3>
                        <p>Enhance your online visibility and drive targeted traffic with our proven SEO strategies.</p>
                        <img src={seoImage} alt="SEO Text" />
                    </article>
                </Link>
                <Link href="/socialmediamarketing">
                    <article>
                        <h3>Social Media Marketing</h3>
                        <p>Engage your audience and build your brand with our comprehensive social media marketing services.</p>
                        <img src={socialmediaImage} alt="A mobile phone with social media apps on the screen" />
                    </article>
                </Link>
            </div>
        </MainSection>
        <MainSection id="why-choose-us">
            <h2>Why Choose AT Digital Consultancy?</h2>
            <p>At AT Digital Consultancy, we are dedicated to your success. Our approach is centered around achieving together, offering personalized solutions that are not just solutions but partnerships.</p>
            <ul>
                <li>Industry Expertise: Leverage our years of experience across various sectors.</li>
                <li>Innovative Solutions: Stay ahead of the curve with our forward-thinking strategies and cutting-edge technologies.</li>
                <li>Results-Driven: We focus on delivering measurable outcomes that drive significant improvements in your business operations.</li>
            </ul>
        </MainSection>
        <MainSection>
            <Cta>
                <p>Ready to build a website that stands out? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="contact.html"><button>Contact us</button></Link>
            </Cta>
        </MainSection>        
    </main>
  );
}

export default Services;