import React from 'react';
import { Link } from 'react-router-dom';
import './EcommercePage.css';
import ecommerceImage from '../../images/ecommerce.avif';
import FAQ from '../../common/faqSection/FAQ';
import Hero from '../../layout/HeroSection';
import StickyHeadingSection from '../../layout/StickyHeadingSection';
import MainSection from '../../layout/MainSection';
import Cta from '../../layout/Cta';

const ecommerceFAQs = [
    {
      question: "What is the typical timeline for developing an eCommerce website?",
      answer: "The timeline for developing an eCommerce website depends on the complexity and size of the project. A simple eCommerce site may take 6-8 weeks, while a more complex site with custom features can take up to 4 months. The timeline includes phases like discovery, design, development, testing, and launch.",
    },
    {
      question: "How much does it cost to build an eCommerce website?",
      answer: "The cost of building an eCommerce website varies based on the scope and features required. A basic eCommerce site can start at a few thousand pounds, while more complex, custom-built eCommerce websites can range from tens of thousands. We offer tailored quotes based on your specific needs.",
    },
    {
      question: "Do you provide website maintenance after launch?",
      answer: "Yes, we offer ongoing website maintenance services to ensure your eCommerce site remains secure, up-to-date, and functional. This includes software updates, security patches, backups, and performance optimization.",
    },
    {
      question: "Will my eCommerce website be mobile-friendly?",
      answer: "Absolutely. We design all our eCommerce websites to be responsive, ensuring they look and function well on all devices, including smartphones, tablets, and desktops.",
    },
    {
      question: "Can you help with SEO for my new eCommerce website?",
      answer: "Yes, we include basic on-page SEO in our eCommerce web development packages, such as optimizing meta tags, headings, and images. We also offer advanced SEO services to help your eCommerce site rank higher in search engine results.",
    },
    {
      question: "Will I be able to update my eCommerce website content myself?",
      answer: "Yes, we build eCommerce websites using various platforms, including content management systems (CMS) like Shopify and WooCommerce, which allow you to easily update content without any technical expertise. We also provide training to help you manage your site.",
    },
    {
      question: "What do you need from me to get started on my eCommerce website?",
      answer: "We will need details about your business, your goals for the eCommerce site, content like product images and descriptions, any branding guidelines, and examples of websites you like. We will guide you through the process during our discovery phase.",
    },
    {
      question: "Do you offer custom eCommerce website development?",
      answer: "Yes, we specialize in developing custom eCommerce websites tailored to your business needs. Whether you need a fully bespoke solution or customization on platforms like Shopify, WooCommerce, or Magento, we can create a functional and user-friendly online store.",
    },
];

  

function Ecommerce() {
    return (
        <main>
        <Hero>
            <img src={ecommerceImage} alt="A laptop with charts" />
            <section>
                <h1>Manage Your Content Seamlessly</h1>
                <p>Unlock the power of Ecommerce websites to streamline content management and enhance your digital presence effortlessly.</p>
            </section>
        </Hero>
        <section class="main-sections" id="cms-section">
            <h2>Content Management Systems</h2>
            <div class="programming">
                <div class="programming-display hidden-left">
                    <i class="fa-brands fa-shopify"></i>
                    <h3>Shopify</h3>
                </div>
                <div class="programming-display hidden-right">
                    <i class="fa-brands fa-wix"></i>
                    <h3>wix</h3>
                </div>
                <div class="programming-display hidden-right">
                    <i class="fa-brands fa-magento"></i>
                    <h3>Magento</h3>
                </div>
                <div class="programming-display hidden-left">
                    <i class="fa-brands fa-squarespace"></i>
                    <h3>Squarespace</h3>
                </div>
                <div class="programming-display">
                    <i class="fa-brands fa-wordpress hidden-right"></i>
                    <h3>Wordpress</h3>
                </div>
                <div class="programming-display">
                  <i class="fa-brands fa-elementor hidden-left"></i>
                  <h3>Elementor</h3>
                </div>       
            </div>
        </section>
        <StickyHeadingSection
      subheading="What challanges we can address for you"
    >
      <div>
        <i class="fa-solid fa-money-bill-transfer"></i>
        <h3>Increased Conversion rate</h3>
        <p>A UI/UX-focused checkout journey can help your customers with buying decisions. Let’s build a better shopping experience.</p>
      </div>
      <div>
        <i class="fa-solid fa-gauge-simple-high"></i>
        <h3>Website speed</h3>
        <p>Customers expect a website to load in 2 seconds or less. We will help you with reaching that speed!</p>
      </div>
      <div>
      <i class="fa-solid fa-arrow-trend-up"></i>
        <h3>Market trends</h3>
        <p>Customers are used to the multi-device buyer journey. Catch up with Ecommerce trends and the latest shopping technology!</p>
      </div>
    </StickyHeadingSection>
    <StickyHeadingSection
    heading="Our Services"
    subheading="How can we help you sell more"
    >
        <div>
            <i class="fa-solid fa-pen-ruler"></i>
            <h3>Strategy and Design</h3>
            <p>We map out an Ecommerce strategy with your sales goals – whether online or in retail. With our team’s combined knowledge and experience in Ecommerce development and analysis, UX/UI design, user journey optimisation, and system integration, we will help you build a step-by-step plan for growth transformation. We will help you with in-depth workflows and business sales analysis, legacy criticalities and risk assessment. Plus, based on the gathered data, we will advise on minimising churn and using value-generating automation.</p>
        </div>
        <div>
            <i class="fa-solid fa-cubes"></i>
            <h3>Implementation and Optimisation</h3>
            <p>We will help you optimise existing systems and build “one-click” Ecommerce experiences. We deliver outstanding customer experiences built on the world’s most popular Ecommerce content management systems – Shopify, Magento, Wordpress and more. And our team of Ecommerce development experts will ensure your systems are highly scalable and optimised – providing your business with system backups, load testing and peak traffic management.</p>
        </div>
        <div>
            <i class="fa-solid fa-arrows-spin"></i>
            <h3>Advanced Systems Integrations</h3>
            <p>We build ecommerce platforms ready for Omnichannel processes and cross-system integrations. We help Ecommerce businesses build Headless systems that make it easy to deploy products across all channels at scale, from site to store to mobile web and apps, giving them a single interface to control product sales in a structured and coherent way. We integrate Ecommerce systems with legacy databases, trading partners, and OMS / POS / PIM / ERP solutions to increase sales effectiveness and business workflow.</p>
        </div>
        <div>
            <i class="fa-solid fa-brain"></i>
            <h3>Future Ecommerce</h3>
            <p>We open doors to the shopping technology of the future – like” no-check-out” shopping or AI/ML-assisted shopping advice. We help companies identify the innovation path fit for their business. Our InnovationsLab team help companies test business hypothesis and build a “digital double” to find the MVP and validate the business idea before investment. AT Digital eCommerce gives you access to dedicated teams across Cloud Computing, Artificial Intelligence, Robotics and IoT technologies – experts who specialise in helping companies build a future-proof business.</p>
        </div>
    </StickyHeadingSection>
    <FAQ faqData={ecommerceFAQs} />
        <MainSection>
            <Cta>
                <p>Ready to build a website that stands out? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="contact.html"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
    </main>
    );
}

export default Ecommerce;