import React from 'react';
import { Link } from 'react-router-dom';
import './SoftwareRecommendation.css';
import MainSection from '../../layout/MainSection';
import softwareRecommendationImage from '../../images/software-recomendation.avif';
import webHosting from '../../images/enterprise-web-hosting.avif';
import erpSolutions from '../../images/erp-solutions.avif';
import onlineOrderingSystem from '../../images/online-ordering-system.avif';
import posImage from '../../images/pos.avif';
import propertyManagementImage from '../../images/property-management.system.avif';
import softwareTesting from '../../images/software-testing.avif';
import Hero from '../../layout/HeroSection';
import Cta from '../../layout/Cta';


function SoftwareRecommendation() {
  return (
    <main>
        <Hero>
            <img src={softwareRecommendationImage} alt="A person on a digital meeting" />
            <section>
                <h1>Software Recommendations</h1>
                <p>Explore our range of innovative software services designed to optimize your business operations and drive growth.</p>
            </section>
        </Hero>
        <MainSection id="guided-services-display">
            <div>
                <h2>Our Software Recommendations</h2>
                <p>Whether you're implementing new Software Recommendations or upgrading existing systems, our comprehensive range of services has you covered. Choose a category below to explore further:</p>
            </div>
            <div className="recommendation-display">
                <Link to="/enterprisewebhosting">
                    <article className="guided-services">
                        <h3>Enterprise Web Hosting</h3>
                        <p>Reliable and scalable web hosting solutions tailored for enterprise-level demands, ensuring high uptime and robust security.</p>
                        <img src={webHosting} alt="Enterprise Web Hosting" />
                    </article>
                </Link>
                <Link to="/erp">
                    <article className="guided-services">
                        <h3>Enterprise Resource Planning (ERP)</h3>
                        <p>Streamline your business processes with our comprehensive ERP solutions designed to integrate all facets of your operations.</p>
                        <img src={erpSolutions} alt="Enterprise Resource Planning" />
                    </article>
                </Link>
                <Link to="/onlineorderingsystem">
                    <article className="guided-services">
                        <h3>Online Ordering System</h3>
                        <p>Enhance your customer experience with our seamless and efficient online ordering system, integrating with payment gateways.</p>
                        <img src={onlineOrderingSystem} alt="Online Ordering System" />
                    </article>
                </Link>
                <Link to="/pos">
                    <article className="guided-services">
                        <h3>Point of Sale (POS)</h3>
                        <p>Optimise your retail operations with our advanced POS solutions designed to improve efficiency and customer satisfaction.</p>
                        <img src={posImage} alt="Point of Sale" />
                    </article>
                </Link>
                <Link to="/pms">
                    <article className="guided-services">
                        <h3>Property Management System (PMS)</h3>
                        <p>Simplify your property management with our comprehensive PMS solutions that streamline operations and enhance tenant satisfaction.</p>
                        <img src={propertyManagementImage} alt="Property Management System" />
                    </article>
                </Link>
                <Link to="/softwaretesting">
                    <article className="guided-services">
                        <h3>Software Testing</h3>
                        <p>Ensure the reliability and quality of your software with our comprehensive testing services, identifying and fixing bugs efficiently.</p>
                        <img src={softwareTesting} alt="Software Testing" />
                    </article>
                </Link>
            </div>
        </MainSection>
        <MainSection>
            <Cta>
                <p>Ready to build a website that stands out? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="contact.html"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
    </main>
  );
}

export default SoftwareRecommendation;