import React from 'react';
import { Link } from 'react-router-dom';
import './ErpPage.css';
import Hero from '../../../layout/HeroSection';
import ErpImage from '../../../images/erp-solutions.avif';
import MainSection from '../../../layout/MainSection';
import Cta from '../../../layout/Cta';

function Erp() {
    return (
        <main className="erp-main">
            <Hero>
                <img src={ErpImage} alt="A card payment terminal" />
                <section>
                    <h1>Enterprise Resource Planning (ERP)</h1>
                    <p>Streamline your business processes with our comprehensive ERP solutions designed to integrate all facets of your operations.</p>
                </section>
            </Hero>
            <MainSection id="why-oracle">
                <h2>Why Oracle ERP is the Ultimate Solution for Your Business</h2>
                <article class="oracle-intro">
                    <p>At AT Digitals, we understand the <b>critical role</b> Enterprise Resource Planning <b>(ERP) plays in</b> streamlining <b>operations</b>, boosting <b>efficiency</b>, and enabling <b>data-driven decisions</b> for businesses of all sizes. We firmly believe Oracle's ERP is the best choice for both small and large enterprises. Its comprehensive suite of integrated applications, unmatched scalability, and advanced analytics deliver robust security, real-time data access, and customisable solutions tailored to your unique business needs. With Oracle's ERP, you can optimise processes, reduce costs, and drive growth, giving your business a competitive edge in today’s dynamic market. Trust AT Digitals to implement the best ERP solution for your success.</p>
                    <div class="video-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/zRyWO3SB1sY?si=6r5WnKz-wuDXdv5C" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </article>
                <p>Oracle ERP Cloud SaaS offers real-time insights, advanced business analytics, and robust decision-making support. Leveraging powerful Generation 2 public cloud technology and an intelligent speech UI, it ensures top-tier performance. Additionally, Oracle continues to provide reliable on-premises ERP Software Recommendations. Trust Oracle ERP for unparalleled efficiency and innovation in your business operations.</p>
            </MainSection>
            <MainSection id="autonomus-database">
                <h2>Oracle Autonomous Database</h2>
                <p>Oracle’s Autonomous Database revolutionises operations by automating routine tasks such as patching, backups, scaling, and tuning. This automation reduces costs, minimises security risks, and ensures automatic data encryption. Key features include:</p>
                <ul>
                    <li>Self-Driving: Automatically provisions, secures, monitors, tunes, and upgrades itself, leading to lower costs and higher productivity.</li>
                    <li>Self-Securing: Protects cloud resources from internal and external threats by encrypting all data, applying security updates without downtime, and using proactive measures to prevent data leaks.</li>
                    <li>Self-Repairing: Maximises uptime and productivity with 99.995% availability, resulting in less than 2.5 minutes of downtime per month. This dynamic adjustment and resource scaling can save up to 70% on runtime costs and 80% on administrative expenses, reducing risks and accelerating time to insight.</li>
                </ul>
            </MainSection>
            <MainSection>
                <h2>Integration Capabilities</h2>
                <p>Oracle Integration offers intelligent automation and integration, combining machine learning, prebuilt integrations, and application adapters. It connects SaaS and on-premise applications, robotic and human processes, and business partners. This platform integrates Oracle ERP Cloud, Engagement Cloud, Marketing Cloud, E-Business Suite, and more, including non-Oracle applications like Salesforce, ServiceNow, and SAP. Oracle's integrated cloud solution ensures rapid business connectivity, faster deployment times, and intuitive tools for system integration, process mapping, and interactive app development.</p>
            </MainSection>
            <MainSection>
                <h2>Technological Innovation</h2>
                <p>Oracle’s strategic approach to emerging technologies includes the integration of AI/ML, blockchain, IoT, and advanced human interfaces into all business applications.</p>
                <ul>
                    <li>Blockchain: Oracle Blockchain Platform, based on Hyper-ledger Fabric, is an enterprise-grade solution offering end-to-end visibility, trust, and reduced development time.</li>
                    <li>AI/ML Digital Assistant: Oracle Digital Assistant enables the creation of AI-powered assistants for natural, expressive interactions with all back-end apps, featuring text and speech interfaces driven by a sophisticated semantic parser.</li>
                </ul>
            </MainSection>
            <MainSection>
                <h2>Comprehensive Software Applications</h2>
                <p>Oracle ERP includes a wide range of cloud applications to support various business needs:</p>
                <ul>
                    <li>Oracle Financials Cloud</li>
                    <li>Project Management</li>
                    <li>Procurement Cloud</li>
                    <li>Risk Management</li>
                    <li>Enterprise Performance Management (EPM)</li>
                    <li>Supply Chain Management (SCM)</li>
                    <li>Adaptive Intelligent Apps for ERP</li>
                    <li>Analytics for Cloud ERP</li>
                </ul>
                <p>Oracle ERP’s robust, secure, and scalable solutions make it an ideal choice for businesses looking to streamline operations, reduce costs, and drive innovation. Let AT Digitals guide you in leveraging Oracle ERP to achieve your business goals.</p>
            </MainSection>
            <MainSection>
                <h2>Key Features</h2>
                <ul>
                    <li>Financial Management</li>
                    <li>Supply Chain Management</li>
                    <li>Human Resources Management</li>
                    <li>Customer Relationship Management</li>
                    <li>Business Intelligence and Reporting</li>
                </ul>
            </MainSection>
            <Cta>
                <p className="cta">Discover the latest offerings powered by technology from <Link to="https://www.oracle.com/uk/erp/" target="_blank" rel="noopener noreferrer">Oracle</Link>.</p>
            </Cta>
        </main>
    );
}

export default Erp;