import React from 'react';
import { Link } from 'react-router-dom';
import './HowWeWork.css';
import MainSection from '../../layout/MainSection';
import Cta from '../../layout/Cta';

function HowWeWork() {
  return (
    <main className="how-we-work">
      <MainSection>
        <h1>How We Work</h1>
        <p>Understanding your needs and delivering effective solutions is our top priority. Here's how our process works:</p>
        <ol>
            <li><strong>Send Your Request:</strong> Start by sending us a brief description of your project or the challenges you're facing through our contact form.</li>
            <li><strong>Project Evaluation:</strong> Our team will review your request to evaluate the scope and requirements of your project. We aim to understand every detail to ensure our solution meets your expectations.</li>
            <li><strong>Quote and Proposal:</strong> Based on the evaluation, we'll provide you with a comprehensive quote and a proposed plan of action tailored to your specific needs.</li>
        </ol>
      </MainSection>
      <MainSection>
      <p>Ready to get started? Reach out to us today, and let's create something amazing together!</p>
        <Cta>
            <Link to="/contact"><button>Contact Us</button></Link>
        </Cta>
      </MainSection>
    </main>
  );
}

export default HowWeWork;
