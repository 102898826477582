import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './ContactPage.css';  // Assuming you have your CSS in this file
import MainSection from '../../layout/MainSection';



function Contact() {
  return (
    <main className="contact-main">
        <section id="contact-form-section">
            <h1>Contact Us</h1>
            <p>Please fill in the form below to send us your inquiries. Whether you need more information about our services or have specific project requirements, we're here to help!</p>
            <form action="https://formspree.io/f/mkgwbnaa" method="POST" enctype="multipart/form-data" name="contact-form">
                <div className="form-group">
                    <label for="name">Name:</label>
                    <input type="text" id="name" name="name" placeholder="Joe Doe" required />
                </div>
                <div className="form-group">
                    <label for="email">Email Address:</label>
                    <input type="email" id="email" name="email" placeholder="joedoe@email.com" required />
                </div>
                <div className="form-group">
                    <label for="company">Company Name:</label>
                    <input type="text" id="company" name="company" placeholder="The Juice Inc." />
                </div>
                <div className="form-group">
                    <label for="service">Service of Interest:</label>
                    <select id="service" name="service" required>
                        <option value="" disabled selected>Select a service</option>
                        <option value="web-dev">Web Development</option>
                        <option value="marketing">Marketing</option>
                    </select>
                </div>
                <div className="form-group">
                    <label for="budget">Budget:</label>
                    <select id="budget" name="budget">
                        <option value="" disabled selected>Select your budget range</option>
                        <option value="Less than £1000">Less than £1000</option>
                        <option value="£1000-£5000">Between £1000-£5000</option>
                        <option value="More than £5000">More than £5000</option>
                    </select>
                </div>
                <div className="form-group">
                    <label for="message">Message</label>
                    <textarea id="message" name="message" rows="4" placeholder="Describe your idea to help us assign the relevant consutation expert." required></textarea>
                </div>
                <button type="submit">Send Message</button>
            </form>
        </section>
    </main>
  );
}

export default Contact;