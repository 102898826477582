import React from 'react';
import { Link } from 'react-router-dom';
import './SocialMediaMarketingPage.css';
import Hero from '../../layout/HeroSection';
import MainSection from '../../layout/MainSection';
import FAQ from '../../common/faqSection/FAQ';
import Cta from '../../layout/Cta';
import SocialMediaMarketingImage from '../../images/social-media.avif';

const socialMediaMarketingFAQs = [
    {
        question: "What digital marketing services do you offer?",
        answer: "We offer a range of digital marketing services, including search engine optimisation (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, email marketing, and more. Our services are customized to meet your specific business objectives."
    },
    {
        question: "How long does it take to see results from digital marketing?",
        answer: "The timeline for seeing results varies depending on the strategy used. SEO can take 3-6 months to show significant improvements, while PPC and social media campaigns can generate leads and traffic almost immediately. Consistency and optimization are key to long-term success."
    },
    {
        question: "How do you measure the success of a digital marketing campaign?",
        answer: "We use various metrics to measure success, including website traffic, conversion rates, lead generation, return on ad spend (ROAS), click-through rates (CTR), and customer engagement. We provide monthly reports to keep you informed of your campaign's performance."
    },
    {
        question: "What is SEO, and why is it important?",
        answer: "Search Engine Optimization is the process of optimizing your website to rank higher in search engine results pages (SERPs). It’s important because higher rankings lead to increased visibility, more organic traffic, and better opportunities to convert visitors into customers."
    },
    {
        question: "What is the difference between organic and paid search?",
        answer: "Organic search results are the listings that appear naturally in search engine results due to their relevance to the search query, while paid search results are advertisements that appear at the top or bottom of the search page. Both strategies are important, and we can help with both SEO (organic) and PPC (paid) campaigns."
    },
    {
        question: "Do you manage social media accounts?",
        answer: "Yes, we offer social media management services where we create, schedule, and publish content on your behalf. We also engage with your audience, monitor your brand’s social media presence, and provide analytics to measure performance."
    },
    {
        question: "How much does digital marketing cost?",
        answer: "The cost of digital marketing varies based on the services you need and the scope of the campaign. We offer customized packages to suit different budgets. A typical monthly retainer for comprehensive digital marketing services can range from £1000 or more."
    },
    {
        question: "Can you help create content for my website and social media?",
        answer: "Yes, we have a team of skilled content creators who can produce high-quality content for your website, blogs, social media, and other digital platforms. This includes written content, graphics, videos, and more."
    },
    {
        question: "What platforms do you use for PPC advertising?",
        answer: "We manage PPC campaigns on various platforms, including Google, Facebook, Instagram, LinkedIn Ads, and more. We will choose the best platforms based on your target audience and business goals."
    },
    {
        question: "How do I get started with your digital marketing services?",
        answer: "To get started, simply contact us for a consultation. We will discuss your business goals, assess your current digital presence, and develop a tailored strategy to help you achieve success online."
    }
];


function Marketing() {
    return (
        <main>
        <Hero>
            <img src={SocialMediaMarketingImage} alt=""/>
            <section>
                <h1>Tired of following others, be the one to follow</h1>
            </section>
        </Hero>
        <section class="main-sections">
            <h2>We Drive the Engagement – You focus on Success!</h2>
            <p>In the digital world, social media marketing is key to communicate, build trust, and reach your target audience. Our Social Media experts are here to promote your brand through various platforms such as Facebook, Instagram, LinkedIn, X, TikTok.</p>
        </section>
        <section class="main-sections">
            <section id="advantages">
                <div class="advantage hidden-bottom">
                    <i class="fa-solid fa-envelopes-bulk"></i>
                    <p>Unique Content Creation</p>
                </div>
                <div class="advantage hidden-bottom">
                    <i class="fa-solid fa-rectangle-ad"></i>
                    <p>Paid Advertising</p>
                </div>
                <div class="advantage hidden-bottom">
                    <i class="fa-solid fa-people-group"></i>
                    <p>Engaging with Followers</p>
                </div>
            </section>
        </section> 
        <section class="main-sections" id="process">
            <h2>OUR GOAL IS TO BUILD BRAND AWARENESS, ENGAGE WITH CUSTOMERS, AND DRIVE TRAFFIC AND CONVERSION.</h2>
            <section class="process-display">
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <h3>Analysis</h3>
                    <p>We begin with thorough analysis of your current social media presence across all platforms. This includes your existing content engagement levels, audience demographics, and key competitors of your market. This helps us understand where you stand and to define the best strategy for your campaign.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-code"></i>
                    <h3>Development</h3>
                    <p>We move to strategy development after completing our audit to set clear objectives based on your business goals, whether it’s boosting brand awareness, driving website traffic, or increasing sales. Part of the strategy also includes setting up KPI’s to measure success.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-pencil"></i>
                    <h3>Content Creation</h3>
                    <p>We develop engaging and relevant content tailored to each platform’s unique format and audience. Visually appealing graphics and videos, interactive posts, while ensuring the aesthetics of your page. Our goal is to create contents that resonates with your audience and fosters meaningful interactions.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-check"></i>
                    <h3>Excecution</h3>
                    <p>We bring the strategy and content to life. This includes scheduling posts in a content calendar to ensure that the content is published at optimal times for maximum visibility and engagement.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-desktop"></i>
                    <h3>Monitoring</h3>
                    <p>Continuous track of the performance of your SM activities. We use analytics tools to measure engagement rates, reach, follower growth, and other key metrics. We stay on top of the business, adapting to real-time feedback and trends to keep your strategy effective.</p>
                </article>
                <article class="process-items hidden-left">
                    <i class="fa-solid fa-list-check"></i>
                    <h3>Report</h3>
                    <p>You can check the progress of the campaigns by the reports that we provide on monthly basis. Highlighting the KPI’s in an easy-to-read format.</p>
                </article>
            </section>
        </section>
        <FAQ faqData={socialMediaMarketingFAQs} />
        <MainSection>
            <Cta>
                <p>Ready to elevate your online presence with cutting-edge marketing strategies? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="contact.html"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
    </main>
    );
}

export default Marketing;