import React from 'react';
import '../styles/Cta.css'; // Ensure you have a CSS file for styling

const Cta = ({children}) => {
  return (
    <section id="cta">
      {children}
    </section>
  );
};

export default Cta;