// src/CoreTeam.js
import React from 'react';
import './CoreTeamPage.css';
import MarkImage from '../../images/MarkKaracsony.jpeg'
import MartinImage from '../../images/MartinGanyasi.jpeg';
import AlbertImage from '../../images/AlbertBerezvay.jpeg';

const CoreTeam = () => {
  const teamMembers = [
    {
      name: 'Mark Karacsony',
      title: 'Co-Founder & Digital Transformation Specialist',
      description:
        'Mark is a Digital Transformation Specialist with a passion for helping businesses embrace cutting-edge technologies. He guides clients through the process of integrating digital solutions into their operations, enhancing productivity, and driving growth in today’s fast-paced digital landscape.',
      image: MarkImage, // Replace with the actual path to Mark's image
    },
    {
      name: 'Martin Ganyasi',
      title: 'Co-Founder & Lead Back-End Developer',
      description:
        'Martin handles the back-end development and database management for our clients, ensuring their websites are robust, secure, and scalable. With expertise in data-driven solutions, Martin provides the backbone of every project, focusing on performance, security, and seamless integration.',
      image: MartinImage, // Replace with the actual path to Martin's image
    },
    {
        name: 'Albert Berezvay',
        title: 'Co-Founder & Lead Web Developer',
        description:
          'Albert specializes in website creation and content management systems, turning client visions into stunning, responsive websites. With a strong focus on user experience and functionality, he ensures that every website is not only beautiful but also tailored to meet each client’s unique business needs.',
        image: AlbertImage,
      },
  ];

  return (
    <main className="core-team">
      <h1>Meet Our Core Team</h1>
      <p>
        Our team is made up of experienced professionals dedicated to helping businesses thrive in the digital age. Meet the people behind AT Digital Consultancy.
      </p>
      <section className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={`${member.name}`} className="team-photo" />
            <h2>{member.name}</h2>
            <h3>{member.title}</h3>
            <p>{member.description}</p>
          </div>
        ))}
      </section>
    </main>
  );
};

export default CoreTeam;
