import React from 'react';
import '../styles/MainSections.css'; // Ensure you have a CSS file for styling

const MainSection = ({ children, id }) => {
  return (
    <section className="main-sections" id={id}>
      {children}
    </section>
  );
};

export default MainSection;
