import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import '../styles/Footer.css'; // Path to your CSS file for styling

function Header() {
  return (
    <footer>
        <section>
            <nav className="footer-nav-links">
                <div>
                    <h3>About</h3>
                    <ul className="footer-nav-links-design">
                        <Link to="/about"><li>Our Company</li></Link>
                        <Link to="/coreteam"><li>Core Team</li></Link>
                        <Link to="/howwework"><li>How we work</li></Link>
                    </ul>
                </div>
                <div>
                    <h3>Services</h3>
                    <ul className="footer-nav-links-design">
                        <Link to="/webdesign"><li>Web Design</li></Link>
                        <Link to="/ecommerce"><li>Ecommerce Development</li></Link>
                        <Link to="/enterprisewebhosting"><li>Enterprise Web Hosting</li></Link>
                        <Link to="/pms"><li>Property Management Systems</li></Link>
                        <Link to="/services"><li>More...</li></Link>
                    </ul>
                </div>
                <div>
                    <h3>Industries</h3>
                    <ul className="footer-nav-links-design">
                        <p><li>Hospitality</li></p>
                        <p><li>Retail</li></p>
                        <p><li>IT</li></p>
                    </ul>
                </div>
            </nav>
        </section>
        <div className="footer-nav-social">
                    <Link to="https://www.linkedin.com/company/104328166/admin/dashboard/" target="_blank" aria-label="Go to our LinkedIn page"><i className="fa-brands fa-linkedin"></i></Link>
                    <Link to="https://www.facebook.com/profile.php?id=61563625701248" target="_blank" aria-label="Go to our Facebook page"><i className="fa-brands fa-facebook"></i></Link>
                    <Link to="https://www.instagram.com/at_digitalconsultancy/" target="_blank" aria-label="Go to our Instagram page"><i className="fa-brands fa-instagram"></i></Link>
                    <Link to="#" aria-label="Go to our X page"><i className="fa-brands fa-x-twitter"></i></Link>
                </div>
        <section className="agency-description">
            <p>Digital Transformation & Web Development Agency</p>
        </section>
        <section className="footer-legal">
            <p>2024 &copy; AT Digital Consultancy</p>
            <Link to="#">Terms & Conditions</Link>
            <Link to="/privacypolicy">Privacy Policy</Link>
        </section>
    </footer>
  );
}

export default Header;