// src/components/FAQ.js
import React, { useState } from 'react';
import './FAQ.css'; // Import your CSS file

const FAQ = ({ faqData }) => { // Accept faqData as a prop
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="main-sections faq-sections">
      {faqData.map((item, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => toggleFAQ(index)}
        >
          <h3 className="faq-question">
            {item.question}
            <i className={`fa-solid ${activeIndex === index ? 'fa-minus' : 'fa-plus'}`}></i>
          </h3>
          <div className="faq-answer">
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default FAQ;

