import React from 'react';
import { Link } from 'react-router-dom';
import './WebDesignPage.css';
import webDesignImage from '../../images/webdesign.avif';
import officeTeam from '../../images/office-team.avif';
import FAQ from '../../common/faqSection/FAQ';
import Hero from '../../layout/HeroSection';
import StickyHeadingSection from '../../layout/StickyHeadingSection';
import MainSection from '../../layout/MainSection';
import Cta from '../../layout/Cta';

const webDesignFAQs = [
    {
      question: "What is the typical timeline for developing a website?",
      answer:
        "The timeline for developing a website depends on the complexity and size of the project. A simple website may take 4-6 weeks, while a more complex site with custom features can take up to 3 months. The timeline includes phases like discovery, design, development, testing, and launch.",
    },
    {
      question: "How much does it cost to build a website?",
      answer:
        "The cost of building a website varies based on the scope and features required. A basic website can start at a few hundred pounds, while more complex, custom-built websites can range from thousands. We offer tailored quotes based on your specific needs.",
    },
    {
      question: "Do you provide website maintenance after launch?",
      answer:
        "Yes, we offer ongoing website maintenance services to ensure your site remains secure, up-to-date, and functional. This includes software updates, security patches, backups, and performance optimization.",
    },
    {
      question: "Will my website be mobile-friendly?",
      answer:
        "Absolutely. We design all our websites to be responsive, ensuring they look and function well on all devices, including smartphones, tablets, and desktops.",
    },
    {
      question: "Can you help with SEO for my new website?",
      answer:
        "Yes, we include basic on-page SEO in our web development packages, such as optimizing meta tags, headings, and images. We also offer advanced SEO services to help your website rank higher in search engine results.",
    },
    {
      question: "Will I be able to update my website content myself?",
      answer:
        "Yes, we build websites using various methods including hard-coded and content management systems (CMS) like WordPress, which allow you to easily update content without any technical expertise. We also provide training to help you manage your site.",
    },
    {
      question: "What do you need from me to get started on my website?",
      answer:
        "We will need details about your business, your goals for the website, content like text and images, any branding guidelines, and examples of websites you like. We will guide you through the process during our discovery phase.",
    },
    {
      question: "Do you offer e-Commerce website development?",
      answer:
        "Yes, we specialize in developing e-Commerce websites using platforms like Shopify, WooCommerce, and Magento. We can help you create a fully functional online store that meets your business needs.",
    }
];
  

function WebDesign() {
    return (
        <main>
        <Hero>
            <img src={webDesignImage} alt="People in front of a computer" />
            <section>
                <h1>Crafting Engaging and Functional Websites that Stand Out</h1>
                <p>From intuitive UI/UX design to fully responsive and dynamic websites, our web design services cover everything you need to establish a strong online presence.</p>
            </section>
        </Hero>
        <section class="main-sections" id="programming-section">
            <h2>Programming languages</h2>
            <div class="programming">
                <div class="programming-display hidden-left">
                    <i class="fa-brands fa-html5"></i>
                    <h3>HTML</h3>
                </div>
                <div class="programming-display hidden-right">
                    <i class="fa-brands fa-css3-alt"></i>
                    <h3>CSS</h3>
                </div>
                <div class="programming-display hidden-left">
                    <i class="fa-brands fa-js"></i>
                    <h3>JavaScript</h3>
                </div>
                <div class="programming-display hidden-right">
                    <i class="fa-brands fa-react"></i>
                    <h3>React</h3>
                </div>
                <div class="programming-display hidden-left">
                    <i class="fa-brands fa-angular"></i>
                    <h3>Angular</h3>
                </div>
                <div class="programming-display hidden-right">
                    <i class="fa-brands fa-python"></i>
                    <h3>Python</h3>
                </div>
                <div class="programming-display hidden-left">
                    <i class="fa-brands fa-java"></i>
                    <h3>Java</h3>
                </div>
                <div class="programming-display hidden-right">
                    <i class="fa-solid fa-database"></i>
                    <h3>SQL</h3>
                </div>
            </div>
        </section>
        <StickyHeadingSection
        subheading="Why Choose AT Digital Consultancy for Web Design?"
        >
            <div>
                <i class="fa-solid fa-pen-ruler"></i>
                <h3>Designs Tailerd to Your Needs</h3>
                <p>We don’t believe in one-size-fits-all. Every design we create is uniquely tailored to reflect your brand’s personality and business goals. From color schemes to typography, every element is carefully chosen to create a cohesive and memorable experience for your visitors.</p>
            </div>
            <div>
                <i class="fa-solid fa-users"></i>
                <h3>User-Centric Approach</h3>
                <p>Great design starts with the user in mind. Our web design process prioritizes user experience (UX) to ensure that your website is not only beautiful but also intuitive and easy to navigate. This leads to higher engagement, lower bounce rates, and more conversions.</p>
            </div>
            <div>
                <i class="fa-solid fa-mobile-screen"></i>
                <h3>Responsive and Adaptive Design</h3>
                <p>With an increasing number of users accessing websites via mobile devices, responsive design is crucial. We create websites that adapt seamlessly to any screen size, ensuring a consistent and optimized experience across all devices.</p>
            </div>
            <div>
                <i class="fa-solid fa-gauge-simple-high"></i>
                <h3>Fast Loading Times</h3>
                <p>We know that speed matters. Our websites are optimized for fast loading times, which not only improves user experience but also boosts your search engine rankings. We use the latest technologies and best practices to ensure your site performs at its best.</p>
            </div>
        </StickyHeadingSection>
        <MainSection>
            <h2></h2>
            <section class="process-display-webdesign">
                <article class="process-items-webdesign hidden-left">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    <h3>Discovery and Strategy</h3>
                    <p>We start by understanding your business, your goals, and your audience. This discovery phase allows us to create a design strategy that aligns with your objectives and sets the foundation for a successful project.</p>
                </article>
                <article class="process-items-webdesign hidden-left">
                    <i class="fa-solid fa-code"></i>
                    <h3>Wireframing and Prototyping</h3>
                    <p>Before we dive into the visuals, we create wireframes and prototypes to map out the structure and flow of your website. This ensures that we’re on the right track and allows for early feedback and adjustments.</p>
                </article>
                <article class="process-items-webdesign hidden-left">
                    <i class="fa-solid fa-pencil"></i>
                    <h3>Visual Design</h3>
                    <p>Once the wireframes are approved, we move on to the visual design stage. Here, we bring your brand to life with custom graphics, colors, and typography that create a cohesive and engaging user experience.</p>
                </article>
                <article class="process-items-webdesign hidden-left">
                    <i class="fa-solid fa-check"></i>
                    <h3>Development and Testing</h3>
                    <p>Our developers take the approved designs and turn them into a fully functional website. We rigorously test the site across different browsers and devices to ensure it’s ready for launch.</p>
                </article>
                <article class="process-items-webdesign hidden-left">
                    <i class="fa-solid fa-desktop"></i>
                    <h3>Launch and Support</h3>
                    <p>After final approval, we launch your website and monitor its performance to ensure everything runs smoothly. We also offer ongoing support to help you with updates, maintenance, and any future needs.</p>
                </article>
                <article class="process-items-webdesign hidden-left">
                    <i class="fa-solid fa-list-check"></i>
                    <h3>Report</h3>
                    <p>You can check the progress of the campaigns by the reports that we provide on monthly basis. Highlighting the KPI’s in an easy-to-read format.</p>
                </article>
            </section>
        </MainSection>
        {/* --- FAQ SECTION --- */}
        <FAQ faqData={webDesignFAQs}/>
        <StickyHeadingSection
            heading="AT Digital Consultancy"
            subheading="Benefits of Choosing Us"
        >
            <div>
                <i class="fa-solid fa-pen-ruler"></i>
                <h3>Custom Solutions</h3>
                <p>We don’t do templates. Every website we create is unique and tailored to your specific needs.</p>
            </div>
            <div>
                <i class="fa-solid fa-sterling-sign"></i>
                <h3>Transparent Pricing</h3>
                <p>No hidden fees. We offer clear and competitive pricing for all our services.</p>
            </div>
            <div>
                <i class="fa-regular fa-clock"></i>
                <h3>Timely Delivery</h3>
                <p>We understand the importance of deadlines and work diligently to deliver on time, every time.</p>
            </div>
            <div>
                <i class="fa-solid fa-headset"></i>
                <h3>Ongoing Support</h3>
                <p>Our relationship doesn’t end at launch. We’re here to support you as your business grows.</p>
            </div>
        </StickyHeadingSection>
        <MainSection id="introduction-webdesign">
            <section>
                <article>
                    <h2>WHO WE ARE</h2>
                    <h3>Your technology partner</h3>
                    <p>We provide our clients with excellent software and high-quality digital products. We’re also committed to building reliable and long-term partnerships. Our in-depth understanding of your business goals, combined with excellent technical expertise, make us a good fit to level up your business, regardless of its maturity.</p>
                    <div>
                    </div>
                </article>
                <img src={officeTeam} alt="An office team"/>
            </section>
        </MainSection>
        <MainSection>
            <Cta>
                <p>Ready to build a website that stands out? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="/contact"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
    </main>
    );
}

export default WebDesign;