import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './SoftwareTestingPage.css';  // Assuming you have your CSS in this file
import MainSection from '../../../layout/MainSection';
import Cta from '../../../layout/Cta';
import Hero from '../../../layout/HeroSection';
import SoftwareTestingImage from '../../../images/software-testing.avif';



function SoftwareTesting() {
  return (
    <main>
        <Hero>
            <img src={SoftwareTestingImage} alt=""/>
            <section>
                <h1>Software Testing</h1>
                <p>Ensure the reliability and quality of your software with our comprehensive testing services.</p>
            </section>
        </Hero>
        <section class="main-sections">
            <h2>Benefits of Our Software Testing Services</h2>
            <p>Our software testing services help you identify and fix bugs, enhance performance, and ensure compliance with industry standards.</p>
        </section>
        <section class="main-sections">
            <h2>Key Features</h2>
            <ul>
                <li>Automated Testing</li>
                <li>Manual Testing</li>
                <li>Performance Testing</li>
                <li>Security Testing</li>
                <li>Compliance Testing</li>
            </ul>
        </section>
        <MainSection>
            <Cta>
                <p>Ready to build a website that stands out? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="contact.html"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
    </main>
  );
}

export default SoftwareTesting;