import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './HomePage.css';  // Assuming you have your CSS in this file
import MainSection from '../../layout/MainSection';
import Cta from '../../layout/Cta';
import Hero from '../../layout/HeroSection';
import StickyHeadingSection from '../../layout/StickyHeadingSection';
import elementorImage from '../../images/Elementor-Logo-Full-Red.svg';
import officeTeam from '../../images/office-team.avif'
import heroImage from '../../images/hero-img.avif';


function HomePage() {
  return (
    <main>
        <Hero>
            <img src={heroImage} alt="The globe" />
            <section>
                <h1>Launch Your Dream Website!</h1>
                <p>Are you a freelancer, entrepreneur, or small business owner? Get a custom-built, responsive website now — just send us your request and we're on it!</p>
                <Link to="/contact"><button>Contact us</button></Link>
            </section>
        </Hero>
        <MainSection id="introduction">
            <section>
                <article>
                    <h2>WHO WE ARE</h2>
                    <h3>Your partner in navigating digital transformation</h3>
                    <p>We are your reliable strategic and technology partner. Our industry-focused experts are committed to helping you turn business challenges into opportunities through digital strategy guidance and innovative software solutions. By harnessing technology as a driver of transformation, we enable our clients to stay ahead and remain competitive in their markets.</p>
                    <div>
                        <Link to='/about'>Get to know us</Link>
                        <Link to='/contact'>Discuss how we can help</Link>
                    </div>
                </article>
                <img src={officeTeam} alt="An office team" loading="lazy"/>
            </section>
        </MainSection>
        <MainSection id="services-articles">
            <h2>Industry Expertise</h2>
            <h3>We excel in the following areas</h3>
            <div className="articles-grid">
                <article className="article-item">
                    <i className="fa-solid fa-code"></i>
                    <h4>Web Design</h4>
                    <p>Transform your ideas into stunning, user-friendly websites with our expert web design services.</p>
                    <Link to="/webdesign" className="article-button" aria-label="Go to web design page">
                        <i className="fas fa-arrow-right"></i>
                    </Link>
                </article> 
                <article className="article-item">
                    <i className="fas fa-shopping-cart"></i>
                    <h4>Ecommerce Development</h4>
                    <p>Build powerful ecommerce websites that drive sales and deliver a seamless shopping experience.</p>
                    <Link to="/ecommerce" className="article-button" aria-label="Go to ecommerce development page">
                    <i className="fas fa-arrow-right"></i>
                    </Link>
                </article>
                <article className="article-item">
                    <i className="fas fa-search"></i>
                    <h4>Search Engine Optimisation</h4>
                    <p>Enhance your online visibility and drive targeted traffic with our proven SEO strategies.</p>
                    <Link to="/seo" className="article-button" aria-label="Go to SEO page">
                        <i className="fas fa-arrow-right"></i>
                    </Link>
                </article>
                <article className="article-item">
                    <i className="fas fa-thumbs-up"></i>
                    <h4>Social Media Marketing</h4>
                    <p>Engage your audience and build your brand with our comprehensive social media marketing services.</p>
                    <Link to="/socialmediamarketing" className="article-button" aria-label="Go to Social Media Marketing page">
                        <i className="fas fa-arrow-right"></i>
                    </Link>
                </article>
            </div>
        </MainSection>
        <StickyHeadingSection
      heading="What makes us unique"
      subheading="We are technical experts with business integrity"
    >
      <div>
        <i className="fa-solid fa-user-gear"></i>
        <p>When partnering with AT Digital, you’re choosing experienced developers, and managers who understand the methodology of software development.</p>
      </div>
      <div>
        <i className="fa-solid fa-handshake"></i>
        <p>Your needs are at the heart of every project. We have an individual approach to each client because we value long-term relationships based on honesty and trust over quick gains.</p>
      </div>
      <div>
        <i className="fa-solid fa-check"></i>
        <p>You can talk to us about any technology challenges you face. We offer a one-stop shop approach as we operate within a wide range of specialities.</p>
      </div>
    </StickyHeadingSection>
        <MainSection id="partners">
            <h2>We work with</h2>
            <div className="logos">
                <div className="logos-slide">
                    <img src="https://www.eukhost.com/images/general/eukhost-logo-new.svg" alt="eUKhost.com logo"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/GoDaddy_logo.svg" alt="GoDaddy.com logo"/>
                    <img src={elementorImage} alt="Elementor logo"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/50/Oracle_logo.svg" alt="Oracle logo"/>
                </div>
                <div className="logos-slide">
                    <img src="https://www.eukhost.com/images/general/eukhost-logo-new.svg" alt="eUKhost.com logo"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/GoDaddy_logo.svg" alt="GoDaddy.com logo"/>
                    <img src={elementorImage} alt="Elementor logo"/>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/5/50/Oracle_logo.svg" alt="Oracle logo"/>
                </div>
            </div>
        </MainSection>
        <MainSection>
            <Cta>
                <p>Ready to elevate your online presence? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="/contact"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
    </main>
  );
}

export default HomePage;
