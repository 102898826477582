import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './DigitalTransformation.css';  // Assuming you have your CSS in this file
import MainSection from '../../layout/MainSection';
import Cta from '../../layout/Cta';
import Hero from '../../layout/HeroSection';
import digtialTransormationImage from '../../images/digital_transformation.avif';


function DigitalTransformation() {
  return (
    <main>
        <Hero>
            <img src={digtialTransormationImage} alt="A hand that points to a computer screen"/>
            <section>
                <h1>Design, Implementation, Support</h1>
                <p>Transform your business from start to finish with our comprehensive services. We provide expert design, seamless implementation, and ongoing support to ensure your digital transformation journey is successful.</p>
            </section>
        </Hero>        
        <MainSection>
            <h2>Digitising Your Service or Product Offering</h2>
            <p className="hidden-bottom">Digital transformation requires latest technology into every part of your business, changing the way you operate and deliver value to your customers. In today's fast-paced world, this shift is crucial for staying competitive, meeting customer demands, and taking advantage of the latest tech advancements. Embracing digital transformation means adopting powerful tools like Artificial Intelligence (AI), Blockchain, Internet of Things (IoT), and integrated software to boost your business performance, increase profits, reduce waste, and enhance employee engagement.</p>
            <p className="hidden-bottom">But digital transformation isn’t just about the tech. It's about people, processes, and culture coming together. Strong leadership is key to driving this change. By aligning IT with your business goals, you can overcome challenges and unlock new opportunities, ensuring your company thrives in the digital age. Embrace digital transformation to innovate, streamline operations, and grow sustainably in an increasingly digital world.</p>
        </MainSection>
        <MainSection>
            <h2>Extensive Expertise in Digital Innovation Across Various Industries</h2>
            <p className="hidden-bottom">At AT Digitals, we've got your back every step of the way. We start by conducting in-depth meetings to understand your business goals and identify areas for improvement. Whether you aim to increase customers, boost revenue, reduce costs, enhance sustainability, or achieve all of these together, we're here to help.</p>
            <p className="hidden-bottom">Our team of experts will craft tailored digital strategies to meet your specific needs. We focus on aligning our solutions with your business objectives, ensuring successful implementation and tangible results. Let us help you navigate the digital landscape, overcome obstacles, and achieve your goals with confidence. Partner with AT Digitals and transform your business for the digital age.</p>
        </MainSection>
        <MainSection>
            <h2>The True Drivers of Success</h2>
            <p className="hidden-bottom">We understand that while cutting-edge technology is crucial, it's the people and processes that truly drive success. Without the right mindset and understanding, technology alone won't make your business efficient or effective. That's why we offer comprehensive training both on-site and off-site, tailored to your needs. Our goal is to ensure that everyone in your business aligns with your vision and knows how to use these powerful tools effectively. With AT Digitals, you can have peace of mind knowing your team is equipped to leverage technology to its fullest potential.</p>
        </MainSection>
        <MainSection>
            <Cta>
                <p>Ready to elevate your online presence? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="contact.html"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
    </main>
  );
}

export default DigitalTransformation;