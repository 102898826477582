import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './PosPage.css';  // Assuming you have your CSS in this file
import MainSection from '../../../layout/MainSection';
import Cta from '../../../layout/Cta';
import Hero from '../../../layout/HeroSection';
import PosImage from '../../../images/pos.avif';



function Pos() {
  return (
    <main>
        <Hero>
            <img src={PosImage} alt=""/>
            <section>
                <h1>Point of Sale (POS)</h1>
                <p>Optimise your retail operations with our advanced POS solutions designed to improve efficiency and customer satisfaction.</p>
            </section>
        </Hero>
        <section class="main-sections">
            <h2>Transform Your Business with Oracle Symphony  PoS Solutions</h2>
            <p>We proudly offer Oracle Symphony , the global leader in point-of-sale (PoS) systems. Oracle Symphony  delivers stable, reliable, and secure POS solutions tailored for restaurants, hotels, resorts, casinos, stadiums, airports, and retail stores worldwide. With over 40 years of excellence, Oracle Symphony  combines cutting-edge hardware, innovative software, and seamless integrations to enhance your business operations.</p>
            <p>Our comprehensive POS solutions streamline front-of-house, back office, and kitchen management, ensuring flawless service at the table, online, and at the front door. Oracle Symphony  empowers businesses with real-time table management, inventory tracking, employee management, and advanced reporting and analytics.</p>
            <p>With Oracle Symphony , you can:
                <ul>
                    <li>Improve operational efficiency with integrated PMS/POS solutions.</li>
                    <li>Enhance customer experiences with quick service and personalised interactions.</li>
                    <li>Boost sales and reduce wait times with self-service kiosks and mobile POS tablets.</li>
                    <li>Ensure robust security and reliability with cloud-based and on-premise solutions.</li>
                </ul>
            </p>
            <p>Join the ranks of successful businesses leveraging Oracle Symphony  to optimise performance and drive growth. Let AT Digitals help you implement the perfect PoS system for your unique needs.</p>
        </section>
        <section class="main-sections">
            <h2>Key Features</h2>
            <ul>
                <li>Real-time Inventory Tracking</li>
                <li>Sales Reporting and Analytics</li>
                <li>Customer Loyalty Programs</li>
                <li>Secure Payment Processing</li>
                <li>Integration with ERP and CRM Systems</li>
            </ul>
        </section>
            <MainSection>
                <Cta>
                    <p>Ready to transform your business operations with cutting-edge POS solutions? Contact us today to learn how Oracle Symphony can enhance your efficiency and customer satisfaction.</p>
                    <Link to="contact.html"><button>Contact us</button></Link>
                </Cta>
            </MainSection>
    </main>
  );
}

export default Pos;