import React from 'react';
import { Link } from 'react-router-dom';
import './AboutPage.css';
import MainSection from '../../layout/MainSection';
import Cta from '../../layout/Cta';
import Hero from '../../layout/HeroSection';
import officeTeam from '../../images/office-team.avif';

function AboutPage() {
  return (
    <main>
        <Hero>
            <img src={officeTeam} alt="An office meeting" />
            <section>
                <h1>Transforming Businesses With Innovation</h1>
            </section>
        </Hero>
        <MainSection id="who-we-are">
            <article>
                <h2>Who we are</h2>
                <p className="hidden-left">AT Digital Consultancy is founded on the principle of partnership — <b>A</b>chieving <b>T</b>ogether what might be unreachable individually. We are a team of dedicated digital strategists, technologists, and innovators committed to transforming businesses through comprehensive digital solutions. Our ethos is built around collaboration, both within our team and with our clients, ensuring that we work hand-in-hand at every step to tailor solutions that not only meet but exceed expectations.</p>
            </article>
            <article>
                <h2>What we do best</h2>
                <p className="hidden-left">At AT Digital Consultancy, our expertise lies in transforming challenges into opportunities through innovative digital strategies. We excel in creating bespoke solutions that integrate seamlessly with client operations, from comprehensive technology audits to sophisticated digital transformation strategies. Our approach is always client-centric, focusing on delivering measurable results that drive growth and enhance operational efficiency. We believe our strength is our ability to listen, understand, and act in our clients’ best interests, consistently delivering solutions that are not just effective but pioneering.</p>
            </article>
        </MainSection>
        <MainSection id="commitments-section">
            <h2>Our commitments</h2>
            <div className="commitments-display">
                <div className="commitments">
                    <h3>Employee Well-being</h3>
                    <i className="fa-solid fa-heart-pulse"></i>
                </div>
                <div className="commitments">
                    <h3>Learning & Development</h3>
                    <i className="fa-solid fa-book"></i>
                </div>
                <div className="commitments">
                    <h3>Environmental Sustainability</h3>
                    <i className="fa-solid fa-leaf"></i>
                </div>
                <div className="commitments">
                    <h3>Community Engagement</h3>
                    <i className="fa-solid fa-people-roof"></i>
                </div>
                <div className="commitments">
                    <h3>Continuous Improvement</h3>
                    <i className="fa-solid fa-chart-line"></i>
                </div>
            </div>
        </MainSection>
        <MainSection id="our-values">
            <h2>Our Core Values</h2>
            <p>At AT Digital Consultancy, our core values are more than just words—they are the foundation of our business operations and guide our every decision. These principles inspire us to achieve excellence in everything we do and to uphold the trust placed in us by our clients and partners.</p>
            <div className="values-display">
                <article className="hidden-bottom">
                    <span>01</span>
                    <h3>Innovation with Impact</h3>
                    <p>We believe that innovation should not only be groundbreaking but also deliver measurable impact. Our approach combines cutting-edge technology with creative thinking to develop solutions that not only meet the current market needs but also anticipate future trends.</p>
                </article>
                <article className="hidden-bottom">
                    <span>02</span>
                    <h3>Integrity and Transparency</h3>
                    <p>Integrity and transparency are at the heart of all our interactions. Whether it’s dealing with clients, partners, or team members, we strive for honesty and openness in our communications and business practices, ensuring a trustful and respectful working environment.</p>
                </article>
                <article className="hidden-bottom">
                    <span>03</span>
                    <h3>Commitment to Excellence</h3>
                    <p>Our commitment to excellence is unwavering. From the smallest task to the largest project, we pursue the highest standards of quality. This relentless pursuit of excellence drives us to continuously improve and deliver outstanding results that exceed expectations.</p>
                </article>
            </div>
        </MainSection>
        <MainSection>
            <Cta>
                <h2>Discover How We Work!</h2>
                <p>Discover how our commitment to excellence can bring your vision to life. Visit our 'How We Work' page to learn more about our methodology and how we can contribute to your success.</p>
                <Link to="/howwework"><button>How we work</button></Link>
            </Cta>
        </MainSection>
    </main>
  );
}

export default AboutPage;
