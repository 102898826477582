import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
/* LAYOUT */
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Button from './components/common/Button';
import Links from './components/common/Links';
/* UTILITIES */
import ScrollToTop from './components/utils/ScrollToTop';
/* PAGES */
import HomePage from './components/pages/HomePage/HomePage';
import AboutPage from './components/pages/AboutPage/AboutPage';
import DigitalTransformationPage from './components/pages/DigitalTransformation/DigitalTransformation';
import SoftwareRecommendation from './components/pages/SoftwareRecommendation/SoftwareRecommendation';
import Contact from './components/pages/ContactPage/ContactPage';
import Services from './components/pages/ServicesPage/ServicesPage';
import WebDesign from './components/pages/WebDesignPage/WebDesignPage';
import Ecommerce from './components/pages/EcommercePage/EcommercePage';
import Seo from './components/pages/SeoPage/SeoPage';
import SocialMediaMarketing from './components/pages/SocialMediaMarketingPage/SocialMediaMarketingPage';
import EnterpriseWebHosting from './components/pages/SoftwareRecommendation/EnterpriseWebHosting/EnterpriseWebHosting';
import Erp from './components/pages/SoftwareRecommendation/ErpPage/ErpPage';
import OnlineOrderingSystem from './components/pages/SoftwareRecommendation/OnlineOrderingSystemPage/OnlineOrderingSystemPage';
import Pos from './components/pages/SoftwareRecommendation/PosPage/PosPage';
import Pms from './components/pages/SoftwareRecommendation/PmsPage/PmsPage';
import SoftwareTesting from './components/pages/SoftwareRecommendation/SoftwareTestingPage/SoftwareTestingPage';
import CoreTeam from './components/pages/CoreTeamPage/CoreTeamPage';
import HowWeWork from './components/pages/HowWeWorkPage/HowWeWork';
import PrivacyPolicy from './components/pages/PrivacyPolicyPage/PrivacyPolicyPage';

function App() {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <div>
        {/* Routing setup */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/digitaltransformation" element={<DigitalTransformationPage />} />
          <Route path="/softwarerecommendation" element={<SoftwareRecommendation />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/webdesign" element={<WebDesign />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/socialmediamarketing" element={<SocialMediaMarketing />} />
          <Route path="/enterprisewebhosting" element={<EnterpriseWebHosting />} />
          <Route path="/erp" element={<Erp />} />
          <Route path="/onlineorderingsystem" element={<OnlineOrderingSystem />} />
          <Route path="/pos" element={<Pos />} />
          <Route path="/pms" element={<Pms />} />
          <Route path="/softwaretesting" element={<SoftwareTesting />} />
          <Route path="/howwework" element={<HowWeWork />} />
          <Route path="/coreteam" element={<CoreTeam />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

