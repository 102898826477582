import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import logo from '../images/FullLogo_NoBuffer-2.avif';
import '../styles/Header.css'; // Path to your CSS file for styling

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
        <nav>
            <Link to="/" onClick={closeMenu}><img src={logo} className="logo" alt="At Digital Consultancy logo"/></Link>
            <i className="nav-icons fa-solid fa-bars" id="menu-bars" onClick={toggleMenu} style={{ display: isMenuOpen ? 'none' : 'block' }}></i>
            <i className="nav-icons fa-solid fa-x" id="menu-close" onClick={toggleMenu} style={{ display: isMenuOpen ? 'block' : 'none' }}></i>
            <ul className={`main-menu ${isMenuOpen ? 'open' : ''}`} id="mainMenu">
                <Link className="nav-links" to="/about" onClick={closeMenu}><li className="menu-items">About</li></Link>
                <Link className="nav-links" to="/digitaltransformation" onClick={closeMenu}><li className="menu-items">Digital Transformation</li></Link>
                <Link className="nav-links" to="/softwarerecommendation" onClick={closeMenu}><li className="menu-items">Software Recommendations</li></Link>
                <Link className="nav-links" to="/services" onClick={closeMenu}><li className="menu-items">Services</li></Link>
                <Link id="contact-button" to="/contact" onClick={closeMenu}><li className="menu-items"><button>Contact</button></li></Link>
            </ul>
        </nav>
    </header>
  );
}

export default Header;
