import React from 'react';
import '../styles/StickyHeadingSection.css'; // Link to the CSS file for this component

const StickyHeadingSection = ({ heading, subheading, children }) => {
  return (
    <div className="unique-section">
        <div className="unique-heading">
            <h2>{heading}</h2>
            <h3>{subheading}</h3>
        </div>
        <article>
            {children}
        </article>
    </div>
  );
};

export default StickyHeadingSection;
