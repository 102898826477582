import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './PmsPage.css';  // Assuming you have your CSS in this file
import MainSection from '../../../layout/MainSection';
import Cta from '../../../layout/Cta';
import Hero from '../../../layout/HeroSection';
import PropertyImage from '../../../images/property-management.system.avif';



function Pms() {
  return (
    <main>
        <Hero>
            <img src={PropertyImage} alt="An image of a property"/>
            <section>
                <h1>Property Management System (PMS)</h1>
                <p>Simplify your property management with our comprehensive PMS solutions that streamline operations and enhance tenant satisfaction.</p>
            </section>
        </Hero>
        <section class="main-sections">
            <h2>Transform Your Hotel Operations with Oracle OPERA Cloud PMS</h2>
            <p>At AT Digitals, we understand the complexities of managing hotel operations. That's why we offer Oracle OPERA Cloud Property Management System (PMS), a comprehensive, cloud-based solution designed to meet the diverse needs of hotels and resorts. Oracle OPERA Cloud simplifies IT, centralises data, and provides an all-in-one platform to enhance guest experiences, manage room inventory, and ensure data security.</p>
        </section>
        <section class="main-sections">
            <h2>Key Features of Oracle OPERA Cloud PMS</h2>
            <ul>
                <li>Integrated Operations: Seamlessly integrates with Oracle's POS and kitchen management solutions for efficient food and beverage operations, maximising guest satisfaction.</li>
                <li>Mobile-Enabled: Empower your staff to serve guests anywhere on the property, reducing check-in/check-out times with real-time updates.</li>
                <li>Customisable Dashboards: Over 30 preconfigured tiles focus on front desk, revenue, inventory, and housekeeping, allowing for ultimate flexibility and operational efficiency.</li>
                <li>Guest Profiles: Gain rich insights into guest preferences and manage loyalty programs to deliver exceptional service.</li>
                <li>Real-Time Insights: Access high-level metrics and individual transactions to make informed decisions, optimise revenue, and enhance guest experiences.</li>
                <li>Advanced Reporting: Build customised reports and graphical dashboards with key performance indicators (KPIs) at a glance.</li>
                <li>Integrated Payment Solutions: Enhance payment data security and provide flexible payment options with Oracle Payment Interface.</li>
            </ul>
        </section>
        <section class="main-sections">
            <h2>Benefits of Choosing Oracle OPERA Cloud PMS</h2>
            <ul>
                <li>Increased Efficiency: Streamline hotel operations with a unified platform that centralises data and automates routine tasks.</li>
                <li>Enhanced Guest Experience: Personalise guest interactions and improve service delivery with mobile-enabled solutions and comprehensive guest profiles.</li>
                <li>Scalability: Ideal for hotels of all sizes, from luxury resorts to limited-service properties, offering flexible functionality and cost-effective solutions.</li>
                <li>Security and Compliance: Ensure legal and fiscal compliance with industry-leading security standards and support for over 200 countries and territories.</li>
                <li>Innovation: Accelerate innovation with open APIs and built-in integration services, allowing for easy adoption of new technologies.</li>
            </ul>
        </section>
        <section class="main-sections">
            <h2>Key Features</h2>
            <ul>
                <li>Tenant and Lease Tracking</li>
                <li>Maintenance Management</li>
                <li>Accounting and Financial Reporting</li>
                <li>Online Payment Processing</li>
                <li>Integration with CRM and ERP Systems</li>
            </ul>
        </section>
        <section class="main-sections">
            <p>Join leading hotels and resorts worldwide that trust Oracle OPERA Cloud PMS to transform their operations. Let AT Digitals help you implement this powerful solution to drive efficiency, enhance guest satisfaction, and grow your business. We'll assist with configurations, software implementation, and provide comprehensive training to ensure your team is fully prepared. Contact us today to learn more and get started with Oracle OPERA Cloud PMS</p>
        </section>
        <MainSection>
            <Cta>
                <p className="cta">Explore PMS solutions powered by industry-leading technology from <Link to="https://www.oracle.com" target="_blank" rel="noopener noreferrer">Oracle</Link>.</p>
            </Cta>
        </MainSection>
    </main>
  );
}

export default Pms;