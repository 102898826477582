import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for navigation within React Router
import './OnlineOrderingSystemPage.css';  // Assuming you have your CSS in this file
import MainSection from '../../../layout/MainSection';
import Cta from '../../../layout/Cta';
import Hero from '../../../layout/HeroSection';
import OrderingImage from '../../../images/online-ordering-system.avif';



function OnlineOrderingSystem() {
  return (
    <main>
        <Hero>
            <img src={OrderingImage} alt=""/>
            <section>
                <h1>Online Ordering System</h1>
                <p>Enhance your customer experience with our seamless and efficient online ordering system.</p>
            </section>

        </Hero>
        <MainSection>
            <h2>Revolutionise Your Hospitality Business with EMenuNow</h2>
            <p>At AT Digitals, we empower hospitality businesses to boost sales and cut costs with an award-winning order and payment solution, EMenuNow. This innovative system integrates seamlessly with any POS, eliminating the need for expensive hardware and complex integrations. Staff can take orders and process payments using any device, supporting table service, click & collect, and delivery, all without the hassle of downloading an app.</p>
            <p>No matter your venue type - restaurants, hotels, pubs, bars, cafés, or transport services - EMenuNow's unique features are designed to enhance efficiency, increase profits, and maximise customer satisfaction. Get set up in under an hour and start experiencing the benefits immediately. Join the ranks of the UK’s most recognisable brands that have embraced EMenuNow to serve more customers for less. Let us help you digitise your order-taking process and elevate your business to new heights.</p>
        </MainSection>
        <MainSection>
            <h2>Key Features</h2>
            <ul>
                <li>Real-time Order Tracking</li>
                <li>Multiple Payment Options</li>
                <li>User-friendly Interface</li>
                <li>Mobile Compatibility</li>
                <li>Integration with CRM and ERP Systems</li>
            </ul>
        </MainSection>
        <MainSection>
            <Cta>
                <p className='cta'>We proudly partner with leading technology providers like <Link to="https://www.emenunow.com/" target="_blank" rel="noopener noreferrer">Emenu</Link> to deliver top-notch solutions.</p>
            </Cta>
        </MainSection>
    </main>
  );
}

export default OnlineOrderingSystem;