import React from 'react';
import { Link } from 'react-router-dom';
import './EnterpriseWebHosting.css';
import Hero from '../../../layout/HeroSection';
import StickyHeadingSection from '../../../layout/StickyHeadingSection';
import ServerImage from '../../../images/enterprise-web-hosting.avif';
import MainSection from '../../../layout/MainSection';
import Cta from '../../../layout/Cta';

function EnterpriseWebHosting() {
    return (
        <main>
        <Hero>
            <img src={ServerImage} alt="" />
            <section>
                <h1>Enterprise Web Hosting</h1>
                <p>Reliable and scalable web hosting solutions tailored for enterprise-level demands.</p>
            </section>
        </Hero>
        <section class="main-sections">
            <h2>Benefits of Our Hosting Services</h2>
            <p>AT Digital’s enterprise web hosting solutions are designed to deliver high performance, unmatched security, and seamless scalability for large businesses managing significant traffic and resource demands. We provide reliable, robust, and customised support to ensure maximum uptime, data protection, and an exceptional user experience. Trust our hosting services to keep your business running efficiently and competitively in today’s digital world.</p>
        </section>
        <section class="main-sections" id="key-features">
            <h2>Key Features</h2>
            <ul>
                <li>99.9% Uptime Guarantee</li>
                <li>Scalable Resources</li>
                <li>24/7 Technical Support</li>
                <li>Advanced Security Measures</li>
                <li>Comprehensive Backup Solutions</li>
            </ul>
        </section>
        <MainSection>
            <Cta>
                <p>Ready to build a website that stands out? Let's turn your vision into reality. Get in touch today and start your journey to success.</p>
                <Link to="contact.html"><button>Contact us</button></Link>
            </Cta>
        </MainSection>
        </main>
    );
}

export default EnterpriseWebHosting;